import { AxiosError, AxiosResponse } from 'axios';

import { SessionService } from '@/services/session/session.service';

import { baseUrl } from '@/config';
import router from '@/router';
import store from '@/store/index';

/**
 * Capture the response output & sync
 * @param response
 */
export function axiosSuccessHandler(response: AxiosResponse): AxiosResponse {
  return response.data;
}
export function axiosErrorHandler(reason: AxiosError): AxiosError {
  if (reason.response.status === 401 || reason.response.status === 403) {
    SessionService.Instantiate().removeSession();
    router.push('/login').then();
  }
  let error;

  if (Array.isArray(reason.response.data)) {
    if (reason.response.data[0].Description !== null) {
      error = reason.response.data[0].Description;
    } else {
      error = reason.response.data[0];
    }
  } else {
    error = reason.response.data;
  }

  error = filterErrorResponse(error, reason.response.headers);

  store.dispatch('error/pushError', error).then();

  /**
   * Return null so parents can check to see if axiosSuccessHandler occurred,
   *    handling of axiosErrorHandler should be done globally above.
   */
  return null;
}
function filterErrorResponse(code: ErrorCode, headers: object): string {
  const lineBreak = '<br/>';
  let remaining = undefined;
  let error: string = code;

  if (headers && headers[HeaderKey.ACCESS_ATTEMPTS]) {
    remaining = 5 - parseInt(headers[HeaderKey.ACCESS_ATTEMPTS]);
  }
  switch (code) {
    case ErrorCode.INVALID_GRANT:
    case ErrorCode.INVALID_USERNAME_OR_PASSWORD:
      error = 'Invalid Email/Password Combination';
      error += lineBreak;
      error += `Please use your ${baseUrl()} Credentials`;
      break;
    case ErrorCode.INVALID_MFA:
      error = 'Invalid MFA Token';
      error += lineBreak;
      error += `Please use your token sent to your device`;
      break;
    case ErrorCode.USER_NOT_CONFIRMED:
      error = 'Email is not verified, please check your junk/spam mailbox.';
      break;
    case ErrorCode.CREDENTIALS_INVALID:
      error = 'Old Password is invalid';
      break;
  }
  switch (code) {
    case ErrorCode.INVALID_GRANT:
    case ErrorCode.INVALID_USERNAME_OR_PASSWORD:
    case ErrorCode.INVALID_MFA:
      if (remaining) {
        error += lineBreak;
        error += lineBreak;
        error += `Attempts remaining: <strong>${remaining}</strong>`;
      }
  }

  return error;
}

enum ErrorCode {
  INVALID_GRANT = 'invalid_grant',
  INVALID_USERNAME_OR_PASSWORD = 'invalid_username_or_password',
  INVALID_MFA = 'invalid_mfa',
  USER_NOT_CONFIRMED = 'User not confirmed',
  CREDENTIALS_INVALID = 'Credentials Invalid',
}
enum HeaderKey {
  ACCESS_ATTEMPTS = 'access_attempts',
}
