export default {
  // global theme for the app
  globalTheme: 'light', // light | dark
  isRTL: false,
  // dark theme colors
  dark: {
    textbase: '#DCDCDC',
    background: '#1c1c1c',
    surface: '#1B262C',
    primary: '#0096c7',
    secondary: '#7db1ce',
    accent: '#59bbbb',
    error: '#FF5252',
    info: '#2196F3',
    success: '#7db1ce',
    warning: '#FFC107',
    accepted: '#05c075',
    checkout: '#60b666',
    cta: '#0B9CED',
    promo: '#9CC28E',
    toggle: '#2b76b8',
  },

  // light theme colors
  light: {
    textbase: '#222222',
    background: '#FFFFFF',
    modal_background: '#e7e7e7',
    surface: '#F6EFE8',
    toolbar: '#f9f9f9',
    primary: '#e19544',
    secondary: '#CF6025',
    accent: '#009C96',
    anchor: '#E86D2A',
    error: '#ef476f',
    info: '#8B8B8B',
    selector: '#606298',
    success: '#4CA952',
    success_light: '#bdf3c2',
    notification_success: '#CDEBCF',
    notification_warning: '#e1beaf',
    notification_error: '#ff7676',
    warning: '#ffd166',
    accepted: '#05c075',
    checkout: '#60b666',
    cta: '#E86D2A',
    promo: '#9CC28E',
    toggle: '#A84F1E',
  },
};
