import Vue from 'vue';

import router from '@/router';
import { BrowserTracing } from '@sentry/tracing';
import { init, vueRouterInstrumentation } from '@sentry/vue';

export class Sentry {
  static init() {
    init({
      Vue,
      dsn: 'https://51276847d3de426eb517e8b2ddeda7ea@o1082870.ingest.sentry.io/6240855',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
          tracingOrigins: [
            'test.my.singular.health',
            'my.singular.health',
            'test.my.3dicomviewer.com',
            'my.3dicomviewer.com',
            /^\//,
          ],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}
