import { JsSource } from '@/models/sources.model';

import { loadScript } from '@/plugins/load';

export class ClickGuard {
  static Instantiate() {
    return new ClickGuard();
  }
  async init() {
    try {
      if (!window.cg_convert) {
        await loadScript(JsSource.CLICK_GUARD);
      }
      window.cg_convert = this.cgConvert;
      // ClickGuard has been disabled by @JamesHill for now, so this will stop the error in console.
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  async conversion() {
    if (!window.cg_convert) {
      await loadScript(JsSource.CLICK_GUARD);
    }
    window.cg_conversion = true;
  }
  private cgConvert(x, y) {
    if (window._cg_convert) window._cg_convert(x || null, y || null);
    else
      setTimeout(() => {
        this.cgConvert(x || null, y || null);
      }, 500);
  }
}
