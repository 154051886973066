/** polyfill */
import Vue from 'vue';
import VueCookies from 'vue-cookies';

import App from './App.vue';
// STYLES
import './assets/scss/theme.scss';
// FILTERS
import './filters/capitalize';
import './filters/currency';
import './filters/formatDate';
import './filters/lowercase';
import './filters/mftp';
import './filters/placeholder';
import './filters/size';
import './filters/trim';
import './filters/uppercase';
// PLUGINS
import './plugins/vue-gtag';
import './plugins/vue-head';
import vuetify from './plugins/vuetify';
import './prototypes';
import router from './router';
import store from './store/index';
import './types/window.shim';

import { GoogleTranslate } from '@/plugins/google-translate';
import { LogRocket } from '@/plugins/logrocket';
import { Sentry } from '@/plugins/sentry';
import { ServiceWorkerHandler } from '@/plugins/service-worker';
import 'core-js/features/reflect';

ServiceWorkerHandler.Instantiate().init();

// LOG ROCKET
LogRocket.init();
GoogleTranslate.init();

// COOKIES
Vue.use(VueCookies);

// SENTRY
if (process.env.NODE_ENV === 'production') {
  Sentry.init();
  LogRocket.setSentrySession();
}
// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== 'production';
vuetify.framework.theme.dark = false;
export default new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
