import { format } from 'date-fns';
import Vue from 'vue';

Vue.filter('formatDate', (value, filterFormat) => {
  if (value) {
    if (value instanceof Date) {
      return format(value, filterFormat || 'MMM dd, yyyy');
    } else {
      return format(parseInt(value), filterFormat || 'MMM dd, yyyy');
    }
  }

  return '';
});

Vue.filter('asDate', (value) => {
  return new Date(value);
});
