import { AuthSession } from '../../models/auth-session';
import { STORAGE_KEYS } from '../storage/storage.keys';
import { StorageService } from '../storage/storage.service';

export class SessionService {
  static Instantiate() {
    return new SessionService(new StorageService());
  }
  storageSvc: StorageService;

  constructor(storageSvc: StorageService) {
    this.storageSvc = storageSvc;
  }

  setSession(session: AuthSession): void {
    this.storageSvc.set(STORAGE_KEYS.Session, JSON.stringify(session));
  }

  getSession(): AuthSession | null {
    const data = this.storageSvc.get(STORAGE_KEYS.Session);

    return data ? (JSON.parse(data) as AuthSession) : null;
  }
  removeSession(): void {
    this.storageSvc.remove(STORAGE_KEYS.Session);
  }
}
