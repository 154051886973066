import config, { OS_TYPE, OS_TYPES } from '@/configs';

const { gaId } = config.analytics;

let prod = process.env.VUE_APP_NODE_ENV === 'production';

let dev = false;

export function setProd(val: boolean) {
  prod = val;
}
export function setDev(val: boolean) {
  dev = val;
}

export const IS_PROD = () => prod;
export const IS_DEV = () => dev;
export const TOKEN_WINDOW_SECONDS = 120;

export function handleFailedRouterPush(error: Error) {
  // Ignore if we don't get to location. As router guard will protect / redirect user
  console.log(error);
}
export function baseUrl() {
  /* istanbul ignore next */
  return process.env.VUE_APP_NODE_ENV === 'production'
    ? 'https://my.3dicomviewer.com'
    : 'https://test.my.3dicomviewer.com';
}
export function parentBaseUrl() {
  /* istanbul ignore next */
  return process.env.VUE_APP_NODE_ENV === 'production' ? 'https://3dicomviewer.com' : 'https://test.3dicomviewer.com';
}
export function mshAssetsBaseUrl() {
  /* istanbul ignore next */
  return process.env.VUE_APP_NODE_ENV === 'production'
    ? 'https://assets.my.3dicomviewer.com'
    : 'https://test.assets.my.3dicomviewer.com';
}
export function dicomAssetsBaseUrl() {
  /* istanbul ignore next */
  return process.env.VUE_APP_NODE_ENV === 'production'
    ? 'https://assets.3dicomviewer.com'
    : 'https://test.assets.3dicomviewer.com';
}

export function os() {
  if (OS_TYPE().includes(OS_TYPES.WIN)) return OS_TYPES.WIN;
  else if (OS_TYPE().includes(OS_TYPES.MAC)) return OS_TYPES.MAC;

  return OS_TYPES.MAC;
}
export const EMAIL_COUNTER = 150;
export const NAME_COUNTER = 50;
export const PASSWORD_COUNTER = 128;
export const FREE_TEXT_COUNTER = 50;

export const COOKIES = {
  COUNTRY_CODE: 'wp_dcm_country_code',
  GOOGLE_ANALYTICS: `ga-disable-${gaId}`,
};
