import Vue from 'vue';
import { VueCookies } from 'vue-cookies';
import VueGtag from 'vue-gtag';
import { VueGtag as VGtagType } from 'vue-gtag';
import * as gtag from 'vue-gtag';

import config from '../configs';
import router from '../router';

import { COOKIES } from '@/config';
import { LogRocket } from '@/plugins/logrocket';

export {};
const { gaId, awId } = config.analytics;

if (gaId) {
  Vue.use(
    VueGtag,
    {
      appName: config.product.name,
      pageTrackerEnabled: true,
      pageTrackerScreenviewEnabled: true,
      config: { id: gaId },
      includes: [{ id: awId }],
    },
    router,
  );
}

export function LogAndLink(cookies: VueCookies, vueGtag: VGtagType) {
  cookies.set(COOKIES.GOOGLE_ANALYTICS, false);
  if (gaId) {
    gtag.optIn();
  }
  vueGtag.linker({
    domains: ['3dicomviewer.com'],
  });
  LogRocket.setGtagSession(vueGtag);
}
export function reTitlePage(vueGtag: VGtagType, newTitle: string) {
  document.title = `${newTitle} - My 3Dicom Viewer`;
  vueGtag.set({
    title: newTitle,
  });
  vueGtag.pageview({
    page_title: newTitle,
    page_location: router.currentRoute.fullPath,
    page_path: router.currentRoute.path,
  });
}
