import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AuthSession } from '@/models/auth-session';

import { environment } from '../environments/environment';

import { axiosErrorHandler, axiosSuccessHandler } from '@/services/http/axios.handler';

export class HttpService {
  token: AuthSession = null;
  client: AxiosInstance;
  constructor() {
    this.client = axios.create();
  }

  async get<T extends any>(endpoint: string, params = null): Promise<any> {
    return this.client
      .get(HttpService.getAxiosUrl(endpoint), this.getAxiosConfig(params))
      .then(axiosSuccessHandler, axiosErrorHandler);
  }
  async post<T extends any>(endpoint: string, params = null, body = null): Promise<any> {
    return this.client
      .post(HttpService.getAxiosUrl(endpoint), body, this.getAxiosConfig(params))
      .then(axiosSuccessHandler, axiosErrorHandler);
  }
  async postParent<T extends any>(endpoint: string, params = null, body = null): Promise<any> {
    return this.client
      .post(HttpService.getAxiosUrl(endpoint), body, this.getAxiosConfig(params))
      .then((x) => x, axiosErrorHandler);
  }
  async postMultiPart<T extends any>(endpoint: string, body: FormData) {
    const config: AxiosRequestConfig = this.getAxiosConfig({});

    delete config.headers['Content-Type'];
    config.data = body;

    return this.client
      .post(HttpService.getAxiosUrl(endpoint), body, config)
      .then(axiosSuccessHandler, axiosErrorHandler);
  }

  async put<T extends any>(endpoint: string, params = null, body = null) {
    return this.client
      .put(HttpService.getAxiosUrl(endpoint), body, this.getAxiosConfig(params))
      .then(axiosSuccessHandler, axiosErrorHandler);
  }

  async putMultiPart<T extends any>(endpoint: string, body: FormData) {
    const config: AxiosRequestConfig = this.getAxiosConfig({});

    delete config.headers['Content-Type'];
    config.data = body;

    return this.client
      .put(HttpService.getAxiosUrl(endpoint), body, config)
      .then(axiosSuccessHandler, axiosErrorHandler);
  }

  async delete<T extends any>(endpoint: string, params = null): Promise<any> {
    return this.client
      .delete(HttpService.getAxiosUrl(endpoint), this.getAxiosConfig(params))
      .then(axiosSuccessHandler, axiosErrorHandler);
  }

  private getAxiosConfig(params): AxiosRequestConfig<any> {
    const config: AxiosRequestConfig = {
      params,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    if (this.token !== null) {
      config.headers.Authorization = `${this.token.token_type} ${this.token.access_token}`;
    }

    return config;
  }
  private static getAxiosUrl(endpoint: string): string {
    return `${environment().apiUrl}${endpoint}`;
  }
}
