/**
 * Before each route update
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { AuthorisedipsService } from '@/services/authorisedips/authorisedips.service';
import { IpService } from '@/services/ip/ip.service';
import { STORAGE_KEYS } from '@/services/storage/storage.keys';
import { StorageService } from '@/services/storage/storage.service';

import { AuthenticationService } from '@/services/authentication/authentication.service';

import { parentBaseUrl } from '@/config';

// This guard is designed to not inject VueRouter in tests, so we should not test this.
/* istanbul ignore next */
if (!process || process.env.NODE_ENV !== 'test') {
  // This guard is designed to not inject VueRouter in tests, so we should not test this.
  /* istanbul ignore next */
  Vue.use(VueRouter);
}

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/Default.layout.vue'),
    children: [
      {
        path: 'Login',
        name: 'login-page',
        component: () => import(/* webpackChunkName: "Login" */ '@/pages/Login.vue'),
      },
      {
        path: 'NotFound',
        name: 'not-found-page',
        component: () => import(/* webpackChunkName: "ProductNotExist" */ '@/pages/ProductNotExist.vue'),
      },
      {
        path: 'Download-Success',
        name: 'download-success-page',
        component: () => import(/* webpackChunkName: "DownloadSuccess" */ '@/pages/DownloadSuccess.vue'),
      },
      {
        path: 'Signup',
        beforeEnter: () => routeTo3DicomUrl('pricing'),
      },
      {
        path: 'Checkout',
        name: 'checkout-page',
        component: () => import(/* webpackChunkName: "Checkout" */ '@/pages/Checkout.vue'),
      },
      {
        path: 'Forgot-Password',
        name: 'forgot-password-page',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/pages/ForgotPassword.vue'),
      },
      {
        path: 'Reset-Password',
        name: 'reset-password-page',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/pages/ResetPassword.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "Home" */ '@/pages/Home.vue'),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: 'Download',
            name: 'Download',
            component: () => import(/* webpackChunkName: "Home" */ '@/pages/Dashboard/Downloads.vue'),
          },
          {
            path: 'Scans',
            name: 'Scan Management',
            component: () => import(/* webpackChunkName: "Home-Scans" */ '@/pages/Dashboard/Scans.vue'),
          },
          {
            path: 'Subscriptions',
            name: 'My Subscriptions',
            component: () => import(/* webpackChunkName: "Home-Subscriptions" */ '@/pages/Dashboard/Subscriptions.vue'),
          },
          {
            path: 'Devices',
            name: 'Connected Devices',
            component: () => import(/* webpackChunkName: "Home-Devices" */ '@/pages/Dashboard/Devices.vue'),
          },
          {
            path: 'Profile',
            name: 'My Profile',
            component: () => import(/* webpackChunkName: "Home-Profile" */ '@/pages/Dashboard/Profile.vue'),
          },
          {
            path: '',
            name: 'Dashboard',
            component: () => import(/* webpackChunkName: "Home-Dashboard" */ '@/pages/Dashboard/Dashboard.vue'),
          },
        ],
      },
      {
        path: 'Subscribe',
        name: 'Subscribe',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "Home-LandingPage" */ '@/pages/LandingPage.vue'),
      },
    ],
  },
  {
    path: '/authentication',
    component: () => import(/* webpackChunkName: "sso-layout" */ '@/layouts/SSO.layout.vue'),
    children: [
      {
        path: 'sso',
        name: 'SSO',
        component: () => import(/* webpackChunkName: "Home-Dashboard" */ '@/pages/SSO/SSO.vue'),
      },
    ],
  },
  {
    path: '/frames/authentication/button',
    name: 'authentication-button',
    component: () => import(/* webpackChunkName: "auth-btn" */ '@/layouts/Simple.layout.vue'),
    children: [
      {
        path: 'wide',
        name: 'wide',
        component: () => import(/* webpackChunkName: "auth-btn" */ '@/components/button/LogInOut.wide.button.vue'),
      },
      {
        path: 'thin',
        name: 'thin',
        component: () => import(/* webpackChunkName: "auth-btn" */ '@/components/button/LogInOut.thin.button.vue'),
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/Error.layout.vue'),
    children: [
      {
        path: '',
        name: 'error',
        component: () => import(/* webpackChunkName: "NotFoundPage" */ '@/pages/error/NotFoundPage.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

function routeTo3DicomUrl(endpoint) {
  window.location.href = `${parentBaseUrl()}/${endpoint}`;
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

export function beforeEach(to, from, next) {
  routeToProd();
  const isAuth: boolean = AuthenticationService.Instantiate().isAuthenticated();

  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!isAuth) {
      return next({
        path: '/login',
        query: { ...to.query, context: to.path },
      });
    }
  }
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({ name: to.name, query: from.query });
  } else {
    return next();
  }
}

/* istanbul ignore next */
function routeToProd() {
  if (window.location.host.includes('singular.health')) {
    const newHref = window.location.href.replace('singular.health', '3dicomviewer.com');

    window.location.replace(newHref);

    return;
  }
  const protectedHosts = ['test.my.singular.health', 'test.my.3dicomviewer.com', 'localhost:4200'];

  if (protectedHosts.includes(window.location.host)) {
    const storage = new StorageService();

    IpService.Instantiate()
      .getMyIpV4()
      .then((ip) => {
        if (!storage.get(STORAGE_KEYS.IpLock) || storage.get(STORAGE_KEYS.IpLock) !== ip) {
          AuthorisedipsService.Instantiate()
            .getIps()
            .then((ips) => {
              if (ips.includes(ip)) {
                storage.set(STORAGE_KEYS.IpLock, ip);
              } else {
                let newHref = window.location.href;

                for (const host of protectedHosts) {
                  newHref = newHref.replace(host, 'my.3dicomviewer.com');
                }
                window.location.replace(newHref);
              }
            });
        }
      });
  }
}

// Not testing router guard implementation
/* istanbul ignore next */
router.beforeEach((to, from, next) => beforeEach(to, from, next));
/* istanbul ignore next */
router.afterEach(() => {});

export default router;
