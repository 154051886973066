export interface MftpSpace {
  UsedSpace: number;
  TotalSpace: number;
}
export enum MftpLifeDuration {
  WEEK = 'WEEK',
  ONE_DAY = 'ONEDAY',
  THREE_DAYS = 'THREEDAYS',
}
export enum MftpStatus {
  UPLOADED = 'UPLOADED',
  AVAILABLE = 'AVAILABLE',
  EXPIRED = 'EXPIRED',
  MOVING = 'MOVING',
  DELETED = 'DELETED',
  UPLOADING = 'UPLOADING',
  FAILED = 'FAILED',
}
export enum MftpMetaType {
  Create = 'CREATE',
  Share = 'SHARE',
  Download = 'DOWNLOAD',
  PublicShare = 'PUBLIC_SHARE',
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  OneDay = 'ONEDAY',
  ThreeDays = 'THREEDAYS',
  Week = 'WEEK',
  Revoke = 'REVOKE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}
export enum MftpFileMeta {
  STL = 'stl',
  DCM = 'dcm',
  VXL = 'vxl',
}
export interface MftpScan {
  Own: boolean;
  LifeDuration: MftpLifeDuration;
  OwnerEmail: string;
  Id: string;
  Size: number;
  FileName: string;
  Fingerprint: string;
  Nickname: string;
  Status: MftpStatus;
  FileMeta: MftpFileMeta;
  UpdatedAt: string;
  CreatedAt: string;
  Expires: string;
  OwnerFullName?: string;
  OwnerAvatar?: string;
}
export interface MftpMeta {
  Type: MftpMetaType;
  Actor: string;
  ActorHydrated?: UserHydrated;
  Acted?: string;
  ActedHydrated?: UserHydrated;
  CreatedAt: string;
}
export interface UserHydrated {
  Email?: string;
  FullName?: string;
  Avatar?: string;
}
export interface MftpEditScanInfoRequest {
  FileName?: string;
  Nickname?: string;
}
export interface MftpScansSimple {
  Storage: MftpSpace;
  Items: Array<MftpScan>;
}
export interface MftpMetaResponse {
  Status: MftpStatus;
  SharedWith: Array<string>;
  Meta: Array<MftpMeta>;
}

export interface MftpInfoResponse {
  Info: MftpMetaResponse;
  MftpScan: MftpScan;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NoContentResult {}
export interface AnalyticsEventBody {
  AnalyticsEvent: AnalyticsEvent;
  SessionUrl: string;
  Data?: any;
}
export enum AnalyticsEvent {
  ErrorMshPriceIdNull = 1,
  AnalyticsMshVerifyEmailPopup = 2,
}
