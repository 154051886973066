import axios, { AxiosInstance } from 'axios';

import { environment } from '@/services/environments/environment';

export class AuthorisedipsService {
  static Instantiate() {
    return new AuthorisedipsService();
  }

  client: AxiosInstance;

  constructor() {
    this.client = axios.create();
  }

  async getIps(): Promise<Array<string>> {
    return this.client.get(`${environment().apiUrl}/api/IP`).then((res) => res.data);
  }
}
