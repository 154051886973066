import { SsoProvider } from '@/models/auth-session';

import { SignInEvent, SignInHandler } from '@/types/apple.shim';
import { RenderOptions } from '@/types/google-translate.shim';
import { BrowserCacheLocation, Configuration, PopupRequest } from '@azure/msal-browser';

export function openDeeplink(deeplink: string): void {
  const iframe = document.createElement('iframe');

  iframe.src = deeplink;
  iframe.width = '0';
  iframe.height = '0';
  iframe.frameBorder = '0';
  document.body.appendChild(iframe);
}

export function getLauncherSsoDeeplink(token: string, provider: SsoProvider): string {
  return `singularlauncher://sso/authorization?handler=msh&access_token=${token}&provider=${provider}`;
}

export function getMsalPopupRequest(path: string): PopupRequest {
  return {
    redirectUri: `${window.location.origin}${path}`,
    scopes: [`api://${process.env.VUE_APP_MSAL_CLIENT_ID}/API`],
  };
}
export function getAppleConfiguration(path: string) {
  return {
    clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
    scope: 'name email',
    redirectURI: `${window.location.origin}${path}`,
    state: 'msh user auth',
    nonce: Date.now().toString(),
    usePopup: true,
  };
}

export function AssignAppleSignInSuccess(callback: SignInHandler): void {
  document.addEventListener(SignInEvent.SUCCESS, callback);
}

export const MSAL_CONFIGURATION: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/common`,
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export const GOOGLE_BUTTON_CONFIGURATION: RenderOptions = {
  theme: 'outlined',
  type: 'standard',
  logo_alignment: 'center',
};
