import { version } from '../../package.json';
import analytics from './analytics';
import icons from './icons';
import theme from './theme';

export default {
  // product display information
  product: {
    name: 'My Singular Health',
    version: version,
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,
};

export function OS_TYPE() {
  return (global.navigator?.userAgentData?.platform || global.navigator?.platform || 'unknown').toLowerCase();
}

export const OS_TYPES = {
  WIN: 'win',
  MAC: 'mac',
};
