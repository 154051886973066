import Vue from 'vue';

Vue.filter('toFormattedBytes', (value) => {
  if (!+value) return '0 Bytes';

  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(value) / Math.log(k));

  return `${parseFloat((value / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
});

Vue.filter('toFormattedGigabytes', (value) => {
  const gigabytes = (parseInt(value, 10) || 0) / Math.pow(1024, 3);

  return `${gigabytes.toFixed(2)} GB`;
});
