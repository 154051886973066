import Vue from 'vue';

import { Interval } from '@/models/product.model';

Vue.filter('toCurrency', (value, currency) => {
  if (typeof value !== 'number') {
    return value;
  }
  let strCurrency = 'USD';

  if (currency) {
    strCurrency = currency.toUpperCase();
  }

  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: strCurrency,
    }).format(value) + ` ${strCurrency}`
  );
});
Vue.filter('toInterval', (value) => {
  if (typeof value !== 'string') {
    return value;
  }

  if (value === Interval.MONTH) {
    return 'Monthly';
  }

  return 'Annual';
});
