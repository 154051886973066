import { NotificationColour } from '@/models/session.model';

import { Snackbar } from '@/store/app/index';

export default {
  setGlobalTheme(context, val) {
    console.log(val);
    context.commit('setGlobalTheme', val);
  },
  setSnackbar(context, snackbar: Snackbar) {
    snackbar.showing = true;
    snackbar.color = snackbar.color || NotificationColour.SUCCESS;
    snackbar.timeout = snackbar.timeout || 0;
    context.commit('SET_SNACKBAR', snackbar);
  },
};
