import configs from '../../configs';
import actions from './actions';
import mutations from './mutations';

const { product, theme } = configs;
const { isRTL, globalTheme } = theme;

// state initial values
const state: AppState = {
  product,

  // app theme dark or light
  globalTheme,

  // themes and layout configurations
  isRTL,
  snackbars: [] as Array<Snackbar>,
};

export interface AppState {
  product: { name: string; version: string };
  globalTheme: string;
  isRTL: boolean;
  snackbars: Array<Snackbar>;
}
export interface Snackbar {
  text: string;
  color?: string;
  timeout?: number;
  showing?: boolean;
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
