import { ShallowResponse } from '@/services/authentication/authentication.service';

import { MfaProvider } from '@/models/profile.model';

export function TypeUserHeaders<T>(response: ShallowResponse<T>): void {
  response.typedHeaders = response.headers && {
    PhoneMask: response.headers.phone_mask,
    PhoneConfirmed: response.headers.phone_confirmed === 'True',
    PrimaryMfa: parseInt(response.headers.primary_mfa) as MfaProvider,
  };
}
