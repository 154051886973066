import { TypeUserHeaders } from '@/services/helpers/header.typing';
import { STORAGE_KEYS } from '@/services/storage/storage.keys';
import { StorageService } from '@/services/storage/storage.service';

import { MSAL_CONFIGURATION } from '@/configs/sso';

import { AuthSession, SsoProvider } from '@/models/auth-session';
import { MfaProvider, UserHeaders, UserHeadersTyped } from '@/models/profile.model';

import { HttpService } from '../http/http.service';
import { SessionService } from '../session/session.service';

import { TOKEN_WINDOW_SECONDS } from '@/config';
import { LogRocket } from '@/plugins/logrocket';
import { PublicClientApplication } from '@azure/msal-browser';

export interface ShallowResponse<T = any> {
  data: T;
  status: number;
  headers?: UserHeaders;
  typedHeaders?: UserHeadersTyped;
}
export class AuthenticationService extends HttpService {
  static Instantiate() {
    return new AuthenticationService(new SessionService(new StorageService()));
  }

  sessionSvc: SessionService;

  constructor(sessionSvc: SessionService) {
    super();
    this.sessionSvc = sessionSvc;
  }

  async login(email: string, password: string, totp: string = null, provider: MfaProvider = MfaProvider.None) {
    const session: ShallowResponse<AuthSession> = await super.postParent<AuthSession>('/api/Me/AccessToken', null, {
      email,
      password,
      token: totp,
      tokenProvider: provider,
    });

    if (session !== null) {
      if ((session.status === 200 || session.status === 202) && session.data !== null) {
        this.addTokenToDom(session.data);
      }
      TypeUserHeaders(session);
    }

    return session;
  }
  async loginSso(provider: SsoProvider, token: string) {
    const session: AuthSession = await super.get<AuthSession>('/api/Me/SSO', {
      provider,
      token,
    });

    if (session !== null) {
      this.addTokenToDom(session);
    }

    return session;
  }
  async verifyOtp(userId: string, otp: string) {
    const session: AuthSession = await super.get<AuthSession>('/api/User/Verify/OTP', { userId, otp });

    if (session !== null) {
      this.addTokenToDom(session);
    }

    return session;
  }
  async logout() {
    if (this.sessionSvc.storageSvc.get(STORAGE_KEYS.SsoProvider)) {
      switch (this.sessionSvc.storageSvc.get(STORAGE_KEYS.SsoProvider)) {
        case SsoProvider.MICROSOFT:
          await AuthenticationService.logoutMSAL();
          break;
      }
      this.sessionSvc.storageSvc.remove(STORAGE_KEYS.SsoProvider);
    }

    this.sessionSvc.removeSession();
  }
  refresh() {
    const expiredSession = this.sessionSvc.getSession();

    return super.post<AuthSession>('/api/Me/RefreshToken', null, {
      token: expiredSession?.refresh_token,
    });
  }

  async get<T extends any>(endpoint: string, params: any = null): Promise<T> {
    await this.checkToken();

    return super.get(endpoint, params);
  }

  async post<T extends any>(endpoint: string, params: any = null, body: any = null): Promise<any> {
    await this.checkToken();

    return super.post(endpoint, params, body);
  }
  async postMultiPart<T extends any>(endpoint: string, body: FormData = null): Promise<any> {
    await this.checkToken();

    return super.postMultiPart(endpoint, body);
  }
  async put<T extends any>(endpoint: string, params: any = null, body: any = null): Promise<any> {
    await this.checkToken();

    return super.put(endpoint, params, body);
  }

  async delete<T extends any>(endpoint: string, params: any = null): Promise<any> {
    await this.checkToken();

    return super.delete(endpoint, params);
  }

  isAuthenticated(): boolean {
    const token = this.sessionSvc.getSession();

    return !!token;
  }

  private async checkToken() {
    const sessionSvc: SessionService = new SessionService(new StorageService());

    const token = sessionSvc.getSession();

    if (token !== null) {
      if (Date.now() > token.expires_in - TOKEN_WINDOW_SECONDS) {
        const session: AuthSession = await this.refresh();

        this.addTokenToDom(session);
      }
      this.token = sessionSvc.getSession();
    }
  }
  ssoToken(): boolean {
    return (
      this.isAuthenticated() &&
      AuthenticationService.parseJwt(this.sessionSvc.getSession().access_token)['amr'] &&
      AuthenticationService.parseJwt(this.sessionSvc.getSession().access_token)['amr'].includes('external')
    );
  }
  private addTokenToDom(session: AuthSession) {
    session.expires_in = session.expires_in * 1000 + Date.now();
    LogRocket.identify(AuthenticationService.parseJwt(session.access_token)['sub']);
    this.sessionSvc.setSession(session);
  }

  private static async logoutMSAL() {
    const msalInstance = new PublicClientApplication(MSAL_CONFIGURATION);

    await msalInstance.handleRedirectPromise();
    await msalInstance.logoutPopup();
  }

  // This is simply for LogRocket, we don't care too much if it doesn't parse correctly.
  /* istanbul ignore next */
  private static parseJwt(token) {
    // eslint-disable-next-line prefer-destructuring
    const base64Url = token.split('.')[1];
    const base64 = base64Url && base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload =
      base64 &&
      decodeURIComponent(
        Buffer.from(base64, 'base64')
          .toString()
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

    if (!jsonPayload) return { sub: null };

    return JSON.parse(jsonPayload);
  }
}
