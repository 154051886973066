export interface Profile {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  UserTypeId: number;
  Email: string;
  EmailConfirmed: boolean;
  MfaRequired: boolean;
  CountryId: string;
  MfaForced?: boolean;
  TrialEnd: string;
  PrimaryMfa?: MfaProvider;
  Authenticator?: boolean;
  PhoneNumberMask?: string;
}
export enum MfaProvider {
  None,
  Authenticator,
  Sms,
}
export interface MfaAuthenticatorSetup {
  QrCode: string;
  HashCode: string;
}
export interface UserHeaders {
  phone_confirmed: 'True' | 'False';
  phone_mask: string;
  primary_mfa: '0' | '1' | '2';
}
export interface UserHeadersTyped {
  PhoneConfirmed: boolean;
  PhoneMask: string;
  PrimaryMfa: MfaProvider;
}
