export interface AppleID {
  auth: AppleIDAuth;
}

export interface AppleIDAuth {
  init(options: AuthInitOptions): void;
}

export interface AppleIDSignInOnSuccessEvent extends Event {
  detail: SignInResponseI;
}

interface AuthInitOptions {
  clientId: string;
  redirectURI: string;
  scope?: string;
  state?: string;
  nonce?: string;
  usePopup: boolean;
}

interface SignInResponseI {
  user?: UserI;
  authorization: AuthorizationI;
}
interface UserI {
  email: string;
  name: NameI;
}
interface NameI {
  firstName: string;
  lastName: string;
}
interface AuthorizationI {
  code?: string;
  state?: string;
  id_token: string;
}
export type SignInHandler = (response: AppleIDSignInOnSuccessEvent) => void;
export enum SignInEvent {
  SUCCESS = 'AppleIDSignInOnSuccess',
}
