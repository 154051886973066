

























import { mapState } from 'vuex';

import config from './configs';

import { ClickGuard } from '@/plugins/clickguard';
import { LogAndLink } from '@/plugins/vue-gtag';

export default {
  computed: {
    ...mapState({
      snackbars: (state) => state['app'].snackbars,
    }),
  },
  async mounted() {
    await ClickGuard.Instantiate().init();
    LogAndLink(this.$cookies, this.$gtag);
    this.$gtag.event('conversion', {
      send_to: `${process.env.VUE_APP_AW_ID}/UDVtCIrv-MADEMmL0MIB`,
    });
    await this.$store.dispatch('cache/bustImageCache');
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href })),
    ],
  },
};
