import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [
      _c("keep-alive", [_c("router-view", { staticClass: "surface" })], 1),
      _vm._l(
        _vm.snackbars.filter(function (x) {
          return x.showing
        }),
        function (snackbar, index) {
          return _c(
            VSnackbar,
            {
              key: snackbar.text + Math.random(),
              style: "top: " + index * 60 + "px",
              attrs: {
                timeout: snackbar.timeout,
                color: snackbar.color,
                top: "",
                "min-width": "240",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function (ref) {
                      var attrs = ref.attrs
                      return [
                        _c(
                          VBtn,
                          _vm._b(
                            {
                              staticClass: "transparent",
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function ($event) {
                                  snackbar.showing = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [
                            _c(VIcon, { attrs: { color: "textbase" } }, [
                              _vm._v("close"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: snackbar.showing,
                callback: function ($$v) {
                  _vm.$set(snackbar, "showing", $$v)
                },
                expression: "snackbar.showing",
              },
            },
            [
              _c("span", {
                staticClass:
                  "textbase--text text-body-1 text-sm-h6 inter-font text-break",
                domProps: { innerHTML: _vm._s(snackbar.text) },
              }),
            ]
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }