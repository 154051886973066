import actions from './actions';
import mutations from './mutations';

const state = {
  errorModal: (error) => {
    console.error(error);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
