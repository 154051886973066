// Global vuex
import Vue from 'vue';
import Vuex from 'vuex';

import AppModule from './app/index';
import CacheModule from './cache/index';
import ErrorModule from './error/index';

Vue.use(Vuex);

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    error: ErrorModule,
    cache: CacheModule,
  },
});

export default store;
