import { IS_DEV, IS_PROD } from '@/config';

interface EnvironmentSettings {
  apiUrl: string;
  production: boolean;
  destroyAfterEach: boolean;
  resetPasswordUrl: string;
}

const test: EnvironmentSettings = {
  apiUrl: 'https://test.api.singular.health',
  production: false,
  destroyAfterEach: false,
  resetPasswordUrl: 'MY_TEST_3DICOM_VIEWER_SECURE',
};
const prod: EnvironmentSettings = {
  apiUrl: 'https://api.singular.health',
  production: false,
  destroyAfterEach: false,
  resetPasswordUrl: 'MY_3DICOM_VIEWER_SECURE',
};
const local: EnvironmentSettings = {
  apiUrl: 'https://localhost:5001',
  production: false,
  destroyAfterEach: false,
  resetPasswordUrl: 'LOCALHOST_ANGULAR',
};

function PROD_OR_DEV() {
  return IS_PROD() ? prod : test;
}
export const environment = () => (IS_DEV() ? local : PROD_OR_DEV());
