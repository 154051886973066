export enum Levels {
  PATIENT = 'Patient',
  MD = 'MD',
  SURGICAL = 'Surgical',
  RESEARCH = 'R&D',
}
export enum Duration {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

export enum Interval {
  YEAR = 'year',
  MONTH = 'month',
}
export interface Diagnostic {
  SubscriptionLevel: Levels;
  CountryIds: Array<string>;
}
export interface NonConformity {
  DisplayText: string;
  Link: string;
  CreatedAt: string;
  PauseSubscriptions: string;
  PullFromSale: string;
}

export interface Invoice {
  invoice_number: string;
  date_paid: string;
  total: number;
  tax: number;
  currency: string;
  pdf_url: string;
  line_items: Array<InvoiceItem>;
}
export interface PaymentMethod {
  brand: string;
  last_4: string;
  exp_month: string;
  exp_year: string;
}
export interface SetupIntent {
  ClientSecret: string;
  PromotionCode: string;
}
export interface CheckoutSubscriptionResponse {
  Total: number;
  Currency: string;
  PaymentIntentClientSecret?: string;
  SetupIntentClientSecret?: string;
  Description: string;
  Interval: string;
  InvoiceId: string;
  SubscriptionId: string;
  NextPaymentDate?: string;
  Discount?: CheckoutDiscount;
  Items?: Array<CartItem>;
}
export interface StripeValidityResponse {
  Description: string;
  Success: boolean;
}
export interface CheckoutDiscount {
  TotalDiscount: number;
  PromotionCode: string;
  Duration: Duration;
  DurationInMonths?: number;
}
export interface AddonDefinition {
  Id: string;
  AddonId: string;
  Level: Levels;
  Text: string;
  Monthly: boolean;
}
export interface SubscriptionAddon {
  Id: string;
  Name: string;
  ProductReference: string;
  ProductSubscriptionLevelId: string;
  StripeProductId: string;
}
export interface SetupIntentError {
  Message: string;
  Processed: boolean;
}

export interface InvoiceItem {
  description: string;
}
export interface Product {
  ProductName: string;
  SubscriptionLevel: Levels;
  FeatureList: Feature[];
  PriceList: Price[];
  BestSeller?: boolean;
  NonConformityId?: string;
}
export interface PriceIdAddress {
  priceId: string;
  address: any;
}

export interface Feature {
  Id: number;
  Description: string;
  Name: string;
}

export interface Price {
  Currency: string;
  Interval: Interval;
  IntervalCount: number;
  PriceDecimal: number;
  PriceId: string;
}

export interface BasicPrice {
  ProductName: string;
  SubscriptionLevel: Levels;
  NonConformityId: string;
  StripeProductId?: string;
}
export interface CheckoutProduct {
  Price: CheckoutPrice;
  ProductName: string;
  SubscriptionLevel: Levels;
  NonConformityId: string;
  UpSellId: string;
  CrossSells: CheckoutPrice[];
}

export interface CheckoutPrice {
  IsAddon: boolean;
  Description: string;
  MaxQuantity: number;
  StripePriceId: string;
  StripeProductId: string;
  Interval: Interval;
  Currency: string;
  PriceDecimal: number;
}

export interface CartItem {
  CanRemove: boolean;
  Description: string;
  Quantity: number;
  Id: string;
  StripeProductId: string;
  Interval: Interval;
  Currency: string;
  PriceDecimal: number;
  DiscountAmount: number;
}
