export async function loadScript(src, attrs = {}) {
  return new Promise((resolve, reject) => {
    let shouldAppend = false;
    let el: HTMLScriptElement = document.querySelector('script[src="' + src + '"]');

    if (!el) {
      el = document.createElement('script');
      el.type = 'text/javascript';
      el.async = false;
      el.defer = false;
      el.src = src;
      for (const key of Object.keys(attrs)) {
        el.setAttribute(key, attrs[key]);
      }
      shouldAppend = true;
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el);

      return;
    }

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', () => {
      el.setAttribute('data-loaded', 'true');
      resolve(el);
    });

    if (shouldAppend) document.head.appendChild(el);
  });
}
