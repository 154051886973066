import axios, { AxiosInstance } from 'axios';

import { IpData } from '@/models/ip.model';

export class IpService {
  static Instantiate() {
    return new IpService();
  }

  client: AxiosInstance;
  constructor() {
    this.client = axios.create({
      timeout: 1000,
    });
  }
  async getMyIp(): Promise<string> {
    return this.client
      .get<any>(`https://api64.ipify.org?format=json`)
      .then((resp) => resp.data)
      .then((x) => x.ip);
  }
  async getMyIpV4(): Promise<string> {
    return this.client
      .get<any>(`https://api.ipify.org?format=json`)
      .then((resp) => resp.data)
      .then((x) => x.ip);
  }

  async getIpCountry<T extends any>(ip: string): Promise<IpData> {
    return this.client.get<any>(`https://pro.ip-api.com/json/${ip}?key=VkFCV1OAa8VmH3a`).then((resp) => resp.data);
  }
}
