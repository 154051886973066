import { STORAGE_KEYS } from './storage.keys';

export class StorageService {
  /**
   * Get the data
   * @param key the key to identify this value
   * @returns Returns a string when the key and value are set. If value not found will return null.
   */
  get(key: STORAGE_KEYS): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Set the value for the given key.
   * @param key the key to identify this value
   * @param value the value for this key
   * @returns Returns a promise that resolves when the key and value are set
   */
  set(key: STORAGE_KEYS, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * Remove any value associated with this key.
   * @param key the key to identify this value
   * @returns Returns a promise that resolves when the value is removed
   */
  remove(key: STORAGE_KEYS) {
    localStorage.removeItem(key);
  }
}
