import { VueGtag } from 'vue-gtag';

import { version } from '../../package.json';

import { baseUrl } from '@/config';
import { LogRocketIdentifyConfig } from '@/types/logrocket.shim';
import { configureScope } from '@sentry/vue';

export class LogRocket {
  static setGtagSession(gtag: VueGtag) {
    window.LogRocket.getSessionURL((sessionURL) => {
      gtag.event('send', {
        hitType: 'event',
        eventCategory: 'LogRocket',
        eventAction: sessionURL,
      });
    });
  }
  static setSentrySession() {
    window.LogRocket.getSessionURL((sessionURL) => {
      configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
  static async getSessionURL() {
    return new Promise<string>((resolve) => {
      window.LogRocket.getSessionURL((sessionURL) => resolve(sessionURL));
    });
  }
  static init() {
    if (window.location.pathname.includes('/frames/authentication/button')) return;
    window.LogRocket &&
      window.LogRocket.init('qfib9l/msh', {
        release: `${version}-${process.env.NODE_ENV}`,
        rootHostname: '3dicomviewer.com',
        mergeIframes: true,
        childDomains: ['*'],
        parentDomain: baseUrl(),
      });
  }
  static identify(userId: string, options?: LogRocketIdentifyConfig) {
    window.LogRocket.identify(userId, options);
  }
}
