import Vue from 'vue';
import { Store } from 'vuex';

import { NotificationColour } from '@/models/session.model';

import { baseUrl, dicomAssetsBaseUrl, mshAssetsBaseUrl, parentBaseUrl } from '@/config';
import { Snackbar } from '@/store/app';

Vue.mixin({
  methods: {
    $_openUrl(href: string = null, target: string = null): void {
      window.open(href, target);
    },
    $_baseParentUrl(endpoint: string = null): string {
      return addOptionalEndpoint(parentBaseUrl(), endpoint);
    },
    $_baseMshAssetsUrl(endpoint: string = null): string {
      return addOptionalEndpoint(mshAssetsBaseUrl(), endpoint);
    },
    $_baseSelfUrl(endpoint: string = null): string {
      return addOptionalEndpoint(baseUrl(), endpoint);
    },
    $_base3DicomAssetsUrl(endpoint: string = null): string {
      return addOptionalEndpoint(dicomAssetsBaseUrl(), endpoint);
    },
    $_notificationSuccess: async (text: string): Promise<void> =>
      sendNotification(this.$store, text, NotificationColour.SUCCESS),
    $_notificationWarning: async (text: string): Promise<void> =>
      sendNotification(this.$store, text, NotificationColour.WARNING),
    $_notificationError: async (text: string): Promise<void> =>
      sendNotification(this.$store, text, NotificationColour.ERROR),
    async $_registerErrorHandlerAsNotifications(): Promise<void> {
      await this.$store.dispatch('error/setGlobalErrorModal', (error) =>
        sendNotification(this.$store, error, NotificationColour.ERROR),
      );
    },
  },
});

function addOptionalEndpoint(url, endpoint = null) {
  if (endpoint === null) {
    return url;
  }

  return `${url}/${endpoint}`;
}
async function sendNotification(store: Store<any>, text: string, color: NotificationColour) {
  await store.dispatch('app/setSnackbar', {
    text: text,
    color,
    timeout: 3000,
  } as Snackbar);
}
