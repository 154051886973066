function getGaId() {
  return process.env.VUE_APP_GA_ID;
}
function getAwId() {
  return process.env.VUE_APP_AW_ID;
}

export default {
  // Google analytics ID
  gaId: getGaId(),
  awId: getAwId(),
};
