import { Workbox } from 'workbox-window';

export class ServiceWorkerHandler {
  static Instantiate() {
    return new ServiceWorkerHandler();
  }

  wb = new Workbox('/sw.js');

  init() {
    if (ServiceWorkerHandler.canServiceWork()) {
      this.wb.addEventListener('waiting', (event) => {
        this.showSkipWaitingPrompt(event).then((r) => {});
      });

      this.wb.register().then((r) => {});
    }
  }

  private static canServiceWork(): boolean {
    return 'serviceWorker' in navigator;
  }

  private async showSkipWaitingPrompt(event) {
    this.wb.addEventListener('controlling', () => {
      window.location.reload();
    });
    this.wb.messageSkipWaiting();
  }
}
