export class GoogleTranslate {
  // Define the callback for Google Translate to pop up on the page
  static init() {
    window.AfterGoogleTranslateLoad = this.AfterGoogleTranslateLoad;
  }
  private static AfterGoogleTranslateLoad() {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
        layout: 2,
      },
      'google_translate_element',
    );
  }
}
